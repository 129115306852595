export interface NavItem {
    linkText: string;
    linkUrl: string;
    key:string;
    type: NavItemType;
}

export enum NavItemType {
    link,
    button
}
