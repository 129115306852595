import * as React from "react";
import Header from "../../sections/header/header";
import { Image} from "react-bootstrap";
import "./previousclients.scss";
import PreviousClientsCbre from './../../assets/cbre-logo.gif';
import PreviousClientsVanquis from './../../assets/vanquis-logo.gif';
import PreviousClientCbs from './../../assets/cbs-logo.gif';
import PreviousClientsEtech from './../../assets/etech-logo.gif';
import PreviousClientsKpmg from './../../assets/kpmg-logo.gif';
import Footer from "../../sections/footer/footer";
interface PreviousClientsProps
{

}
const PreviousClients: React.FC<PreviousClientsProps> = (props) => {

    return <>
        <div className="previousclients-bg1"><Header/>
            <div className="content-row">
                <div className="container-md">
                    <div className="row">
                        <div className="col previousclients-overlay">
                            <div className="previousclients-overlay-welcomeText">
                                <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                                    <h1 className="previousclients-overlay-welcomeText">Previous Clients</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="previousclients-bg3">
            <div className="content-row">
                <div className="container-md">
                    <div className="row">
                        <div className="col-12 previousclients top">
                            <div className="col-12 previousclients-panel-container mx-auto my-5">
                                <div className="row">
                                    <div className="col-md-6 previousclients-body text-center">
                                        <div className="previousclients-imgbg my-3">
                                            <Image alt="Azure" src={PreviousClientsCbre} className="img-fluid previousclients-img"/>
                                        </div>
                                        <h4 className="previousclients-subtitle">CBRE</h4>
                                        <p className="previousclients-emphasis">.Net Core, Azure, Microservices, CQRS, React, Typescript</p>
                                        <p>Developed a strategic health and safety tracking solution with a React Frontend and .Net Core backend, as part of a 16 strong multi-discipline team.</p>
                                        <p>Following initial release to 65,000 users in January 2020 the solution has been well received by the business.</p>
                                    </div>
                                    <div className="col-md-6 previousclients-body text-center">
                                        <div className="previousclients-imgbg my-3">
                                            <Image alt=".Net Core" src={PreviousClientsVanquis} className="img-fluid previousclients-img"/>
                                        </div>
                                        <h4 className="previousclients-subtitle">Vanquis Bank</h4>
                                        <p className="previousclients-emphasis">.Net Core, Azure, Dynamics CRM, Umbraco, Angular</p>
                                        <p>Part of a team of 9, developing a new loans product for the bank. Primarily writing plugins for Dynamics CRM system to load eligible customers.</p>
                                        <p>Subsequently worked on migration of website to Azure. A highlight of which was a resilience piece I wrote that saved over 3,500 application from being lost in its first 6 months.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 previousclients-body text-center">
                                        <div className="previousclients-imgbg my-3">
                                            <Image alt="SQL Server" src={PreviousClientCbs} className="img-fluid previousclients-img"/>
                                        </div>
                                        <h4 className="previousclients-subtitle">Coventry Building Society</h4>
                                        <p className="previousclients-emphasis">.Net 4.5.2, AngularJS, SOA, Web API, MongoDB</p>
                                        <p>Part of a team of 6 integrating a new third-party provider into the organisations existing website and systems.</p>
                                        <p>Subsequently developed an automated deployment UI in Angular to add a level of CI/CD into the organisation's existing release management framework and infrastructure.</p>
                                    </div>
                                    <div className="col-md-6 previousclients-body text-center">
                                        <div className="previousclients-imgbg my-3">
                                            <Image alt="MongoDB" src={PreviousClientsEtech} className="img-fluid previousclients-img"/>
                                        </div>
                                        <h4 className="previousclients-subtitle">eTech</h4>
                                        <p className="previousclients-emphasis">.Net 4.6, Web API, Azure, SQL Server, JQuery, AngularJS</p>
                                        <p>Led teams ranging from 2-6 people, taking responsibility for leading planning sessions, coordinating with BA/QA functions and clients</p>
                                        <p>Responsible for implementation &amp; architectural decisions on several key projects, including a service for processing survey data received from mobile devices and a system for transmitting that data to a third-party system via service bus.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 previousclients-body text-center">
                                        <div className="previousclients-imgbg my-3">
                                            <Image alt="SQL Server" src={PreviousClientsKpmg} className="img-fluid previousclients-img"/>
                                        </div>
                                        <h4 className="previousclients-subtitle">KPMG</h4>
                                        <p className="previousclients-emphasis">.Net 4.5, Silverlight, SQL Server, WCF, Web API</p>
                                        <p>Part of a team of 5 people developing a remediation platform in Silverlight.</p>
                                        <p>One particularly notable success was the generic rules engine we wrote that neatly abstracted conditional business logic for processing claims.</p>
                                    </div>
                                    <div className="col-md-6 previousclients-body text-center">
                                        <p> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>;
}

export default PreviousClients;