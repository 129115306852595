import * as React from "react";
import Header from "../../sections/header/header";
import "./testimonials.scss";
import Footer from "../../sections/footer/footer";
interface TestimonialsProps
{

}
const Testimonials: React.FC<TestimonialsProps> = (props) => {

    return <>
        <div className="testimonials-bg1"><Header/>
            <div className="content-row">
                <div className="container-md">
                    <div className="row">
                        <div className="col testimonials-overlay">
                            <div className="testimonials-overlay-welcomeText">
                                <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                                    <h1 className="testimonials-overlay-welcomeText">Testimonials</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="testimonials-bg3">
            <div className="content-row">
                <div className="container-md">
                    <div className="row">
                        <div className="col-12 testimonials top">
                            <div className="col-12 mx-auto my-5">
                                <div className="row">
                                    <div className="col-md-12 testimonials-body my-2">
                                        <div className="row">
                                            <div className="col-md-12 py-3 px-5">
                                                <p className="testimonials-firstpara">Joel is a very well respected and highly regarded member of our development team. His many years experience as a senior software engineer are evident in his ability to take on the most difficult of challenges anywhere in the stack, frequently and willingly getting out of his comfort zone to become an expert in all areas from React/Redux progressive web applications to .NET Core, Azure Functions, Cognitive Services, SQL, NoSQL, DevOps, REST, CQRS and Azure Cloud architecture in general.</p> 
                                                <p>Joel's dedication, team spirit and humour in the face of aggressive deadlines and standards has contributed massively to our team's ability to get through the toughest of challenges and succeed. Many times for our team, Joel has been the guy you call with a problem and come away with a solution in sight. </p>
                                                <p>One of many examples would be a SQL stored procedure that crashed the application, which Joel optimised to sub 1 second. Joel has the rare and enviable ability to remain calm and pragmatic under pressure and let the code do the talking. In all respects. working with Joel has undoudbtedly made me a better software developer.</p>
                                                <p className="testimonials-emphasis text-right">Daniel Cook, Software Engineering Tech Lead, CBRE</p>
                                                <p className="text-right">June, 2020</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 testimonials-body my-2">
                                        <div className="row">
                                            <div className="col-md-12 py-3 px-5">
                                                <p className="testimonials-firstpara">Joel is an incredibly bright, dedicated and skilled developer. His ability to not just resolve software issues, but resolve them in the right way is excellent and his dedication to delivering high quality work at a fast pace continually impressed me. He fits really well into teams; whilst working for me he played a key role in delivering a £5.5M C# project, I then recruited him into another team I was managing, which performed completely different work (DevOps) and he picked it up and ran with it immediately.</p>
                                                <p>He understands his own skill gaps and then fills them by spending time outside of work learning new technologies and approaches.</p>
                                                <p>I would not hesitate to hire him again, he's an asset to any team.</p>
                                                <p className="testimonials-emphasis text-right">Davoc Bradley, Senior Development Manager, Coventry Building Society</p>
                                                <p className="text-right">June, 2018</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 testimonials-body my-2">
                                        <div className="row">
                                            <div className="col-md-12 py-3 px-5">
                                                <p className="testimonials-firstpara">Joel and I worked at Coventry Building Society. I was IT PM and Joel was a Snr .Net developer on a project replacing the Society’s Decision Engine.</p>
                                                <p>The project was very challenging, technically due to legacy systems and knowledge base and required the whole team to work very collaboratively. Joel brought his industry experience and skill to help progress the project. His ‘can do’ attitude and pragmatic approach, really helped us solve some difficult issues. His tenacity in resolving tough code issues meant that we could move forward.</p> 
                                                <p>He also mentored less experienced members of the team and was never afraid to think outside the box. I would definitely work with Joel again, he was and remains an asset to any team.</p>
                                                <p className="testimonials-emphasis text-right">Tania Brawn, Project Manager, Coventry Building Society</p>
                                                <p className="text-right">May, 2018</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>;
}

export default Testimonials;