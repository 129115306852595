import * as React from "react";
import { NavBar } from "../navbar/navbar";
import { globalNavItems } from "../../common/state/navItemState";

interface HeaderProps{

}

const Header: React.FC<HeaderProps> = (props) => {
    return <div className="header-row text-center">            
        <header className="header clearfix">
            <NavBar navItems={globalNavItems}/>
        </header>
    </div>;
}
export default Header;