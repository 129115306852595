import * as React from "react";
import Header from "../../sections/header/header";
import "./myfeaturedskills.scss";
import { Image} from "react-bootstrap";
import SkillsAzure from './../../assets/home-skills-azure.gif';
import SkillsCSharp from './../../assets/home-skills-csharp.gif';
import SkillsNetCore from './../../assets/home-skills-netcore.gif';
import SkillsReact from './../../assets/home-skills-reactjs.gif';
import SkillsSqlServer from './../../assets/home-skills-sqlserver.gif';
import SkillsMongo from './../../assets/home-skills-mongo.gif';
import Footer from "../../sections/footer/footer";
interface FeaturedSkillsProps
{

}
const MyFeaturedSkills: React.FC<FeaturedSkillsProps> = (props) => {

    return <>
    <div className="featuredskills-bg1"><Header/>
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col featuredskills-overlay">
                        <div className="featuredskills-overlay-welcomeText">
                            <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                                <h1 className="featuredskills-overlay-welcomeText">Featured Skills</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="featuredskills-bg3">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 featuredskills top">
                        <div className="col-12 featuredskills-panel-container mx-auto my-5">
                            <div className="row">
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt="Azure" src={SkillsAzure} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">Azure</h4>
                                    <p>5 years experience with Azure including App Services, Functions, SignalR, ServiceBus &amp; CosmosDB to name but a few.</p>
                                    <p>We also have experience with CI/CD in Azure DevOps, creating pipelines to deploy code and infrastructure to Azure</p>
                                </div>
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt=".Net Core" src={SkillsNetCore} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">.Net Core</h4>
                                    <p>5 years experience with .Net Core upto v3.1.</p>
                                    <p>This is our speciality! We are vehement about quality, applying SOLID principles and a robust testing regime wherever appropriate.</p>
                                    <p>Also github experts and comfortable working within an agile environment.</p>
                                </div>
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt="C Sharp" src={SkillsCSharp} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">C#</h4>
                                    <p>15 years experience writing software with C# up to v8. </p>
                                    <p>C# underpins our other programming skills and before .Net Core was being used mainstream, we were working with .Net Framework up to v4.6 for around 15 years.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt="SQL Server" src={SkillsSqlServer} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">SQL Server</h4>
                                    <p>15 years experience with SQL Server up to v2019 and including Azure PaaS SQL.</p>
                                    <p>For storing, querying and aggregating over large amounts of relational tabular data, SQL Server continues to be an excellent tool for the job. </p>
                                    <p>We are specialists at optimising SQL performance - a skill exercised recently during engagement with CBRE.</p>
                                </div>
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt="MongoDB" src={SkillsMongo} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">MongoDB</h4>
                                    <p>7 years experience working with NoSQL databases, particularly MongoDB and CosmosDB.</p>
                                    <p>For complex models, often nested several levels deep, which are typical in modern web applications, schema-less/NoSQL databases are an excellent, flexible and scalable choice.</p>
                                </div>
                                <div className="col-md-4 featuredskills-body text-center">
                                    <div className="featuredskills-imgbg my-3">
                                        <Image alt="ReactJs" src={SkillsReact} className="img-fluid featuredskills-img"/>
                                    </div>
                                    <h4 className="featuredskills-subtitle">ReactJs</h4>
                                    <p>1 year working with ReactJs.</p>
                                    <p>We work with a number of other JS frameworks (Specifically Angular &amp; Vue,), but ReactJs is our preferred choice. </p>
                                    <p>During our CBRE engagement up to half of our time was spent working with ReactJs, Redux &amp; Sass creating and editing responsive UIs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer/>
    </>;
}

export default MyFeaturedSkills;