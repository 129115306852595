import { webEnquiry } from "../models/webEnquiry";

export const defaultWebEnquiry: webEnquiry = {
    firstName: "",
    lastName: "",
    comment: "",
    phoneNumber:"",
    emailAddress: "",
    recaptchaToken:"",
    signalrConnectionId:""
}