import * as React from "react";
import { Row, Col, Form, InputGroup, Button, Toast } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { webEnquiry } from "../../common/models/webEnquiry";
import { defaultWebEnquiry } from "../../common/state/webEnquiryState";
import * as signalR from "@microsoft/signalr";
import { apiPost } from "../../common/helpers/api-helper";
import * as yup from 'yup';
interface ContactFormProps {
    signalrUrl: string | undefined;
}

const ContactForm: React.FC<ContactFormProps> = (props) =>{
    
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formData, setFormData ] = React.useState<webEnquiry>(defaultWebEnquiry);
    const [formValidated, setFormValidated ] = React.useState<boolean>(false);
    const [formValidationErrors, setFormValidationErrors ] = React.useState<yup.ValidationError[]>([]);
    let hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(props.signalrUrl === undefined ? "" : props.signalrUrl)
        .configureLogging(signalR.LogLevel.Information)  
        .build()
    let userId: string = "Joel Blake";
    const [hubConnectionState] = React.useState({hubConnection, userId});
    const [show, setShow] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState<any>({});
  
    if(hubConnectionState.hubConnection.state === signalR.HubConnectionState.Disconnected){
        hubConnectionState.hubConnection.start();
        console.log(hubConnectionState.hubConnection.connectionId);
    }
    React.useEffect(() => {
        hubConnectionState.hubConnection.on("setClientMessage", message => {
            if(!show){
                if(!message.IsSuccess){
                    setFormValidated(false);
                }
                setToastMessage(message)
                setShow(true);
                console.log("show toast!");
            }
        });
      });
    let phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    let schema = yup.object().shape({
        firstName: yup.string().required('Please provide your first name'),
        lastName: yup.string().required('Please provide your last name'),
        emailAddress: yup.string().required('Please provide an email address').email('Please provide a valid email address'),
        phoneNumber: yup.string().notRequired().matches(phoneRegEx, 'Please provide a valid phone number'),
        comment: yup.string().required('Please provide a comment')
      });

    const handleChange = (e: any, input: any) =>{
        setFormData({...formData, [input]: e.target.value})
        setFormValidationErrors([])
    }
    const hasErrors = (e: string) =>{
        return formValidationErrors.find(err=>err.path === e) !==undefined
    }

    const handleSubmit = async (e: any) =>{
        e.preventDefault();      
        e.stopPropagation();
        try {
            schema.validateSync(formData, { abortEarly: false });
            let response = await executeRecaptcha!("home_contact");
            let enquiryPayload: webEnquiry ={
                ...formData,
                recaptchaToken: response,
                signalrConnectionId: hubConnectionState.hubConnection.connectionId
            };
            setFormData(enquiryPayload);        
            sendEnquiry(enquiryPayload);  
            setFormValidated(true);
        }
        catch(err){
            if(err instanceof yup.ValidationError){
                console.log(err.inner);
                setFormValidationErrors(err.inner);
            }  
            setFormValidated(false);
        }
    }
    const sendEnquiry =  (enquiryPayload: webEnquiry) =>{
        //send enquiry to backend.
        apiPost("webenquiry", JSON.stringify(enquiryPayload))
        .then(response => {
            if(response.ok){
                console.log("Success!"); 
            }
            else{
                console.log(`http error: ${response.status} ${response.statusText}`)
            }
        })
        .catch(e=>{
            console.log(`exception: ${e}`)
        });
    }
    return <>    
        <Toast show={show} onClose={() => setShow(false)} delay={3000} animation={true} style={toastMessage.IsSuccess ? {} : {backgroundColor: "rgba(240,128,128, 0.85)"}} autohide>
          <Toast.Header>
            <strong className="mr-auto">{toastMessage.IsSuccess ? "Success" : "Error"}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage.Message}</Toast.Body>
        </Toast>
        <Form className="needs-validation" noValidate={true} onSubmit={handleSubmit}>
            <Row>
                <Col md={6} className="contact-body">
                    <Form.Group className="input-group mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="fa fa-user icon"></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={ e => handleChange(e,'firstName') } type="text" className="form-control" placeholder="First Name" aria-label="First Name" aria-describedby="basic-addon1" isInvalid={hasErrors('firstName')} />
                        <Form.Control.Feedback type="invalid">
                            {formValidationErrors
                            .filter(e=>e.path==='firstName')
                            .map(e=>e.message)
                            .join('\r\n')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} className="contact-body">
                    <Form.Group className="input-group mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="fa fa-user icon"></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={ e => handleChange(e,'lastName') } type="text" className="form-control" placeholder="Last Name" aria-label="Last Name" aria-describedby="basic-addon1" isInvalid={hasErrors('lastName')} />
                        <Form.Control.Feedback type="invalid">
                            {formValidationErrors
                            .filter(e=>e.path==='lastName')
                            .map(e=>e.message)
                            .join('\r\n')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>            
                <Col md={12} className="contact-body">
                    <Form.Group className="input-group mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="fa fa-at icon"></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={ e => handleChange(e,'emailAddress') } type="text" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="basic-addon1" isInvalid={hasErrors('emailAddress')}/>
                        <Form.Control.Feedback type="invalid">
                            {formValidationErrors
                            .filter(e=>e.path==='emailAddress')
                            .map(e=>e.message)
                            .join('\r\n')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>                                              
                <Col md={12} className="contact-body">
                    <Form.Group className="input-group mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="fa fa-phone icon"></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={ e => handleChange(e,'phoneNumber') } type="text" className="form-control" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon1"  isInvalid={hasErrors('phoneNumber')} />
                        <Form.Control.Feedback type="invalid">
                            {formValidationErrors
                            .filter(e=>e.path==='phoneNumber')
                            .map(e=>e.message)
                            .join('\r\n')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>                                              
                <Col md={12} className="contact-body">
                    <Form.Group className="input-group mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="fa fa-envelope icon"></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={ e => handleChange(e,'comment') } as="textarea" className="form-control" placeholder="Comment" aria-label="Comment" aria-describedby="basic-addon1" isInvalid={hasErrors('comment')} />
                        <Form.Control.Feedback type="invalid">
                            {formValidationErrors
                            .filter(e=>e.path==='comment')
                            .map(e=>e.message)
                            .join('\r\n')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>                                              
                <Col md={12} className="contact-body">
                    <Button type="submit" disabled={formValidated} className="contact-btn">{formValidated?"Submitted!":"Submit"}</Button>
                </Col>
            </Row>
        </Form> 
                                
    </>
}

export default ContactForm;