import * as React from "react";
import Header from "../../sections/header/header";
import { Image} from "react-bootstrap";
import "./services.scss";
import ServicesContracting from './../../assets/services-contracting.jpg';
import ServicesSmallBusiness from './../../assets/services-smallbusiness.jpg';
import { globalNavItems } from "../../common/state/navItemState";
import Footer from "../../sections/footer/footer";
interface ServicesProps
{

}
const Services: React.FC<ServicesProps> = (props) => {

    return <>
    <div className="services-bg1"><Header/>
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col services-overlay">
                        <div className="services-overlay-welcomeText">
                            <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                                <h1 className="services-overlay-welcomeText">Services</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="services-bg3">
        <div className="content-row">
            <div className="container-md services-container">
                <div className="row">
                    <div className="col-md-12 services top mb-5">
                        <div className="col-md-6 services-panel my-auto text-left">
                            <h1 className="font-weight-bold" style={{fontSize: "3.5em"}}>Contracting</h1>
                            <p>I've been contracting since 2008 and have worked on both public facing and internal projects across banking, financial services and real estate amongst others. </p>
                            <p>Some examples include:</p> 
                            <ul>
                                <li>Multi-million pound mortgage application solution</li>
                                <li>Credit card application resiliency project that saved over 3,500 applications from being lost in its first 6 months</li>
                                <li>Health and safety regulatory reporting solution with c. 10,000 concurrent users.</li>
                            </ul>
                            <p>This stream of the business has produced the most challenging and interesting problems, and that's what we are passionate about solving! Please <a href={globalNavItems.find(n=>n.key==="contactus")?.key}>{globalNavItems.find(n=>n.key==="contactus")?.linkText}</a> to see how we can help with your requirements.</p>
                        </div>
                        <div className="col-md-6 services-panel">
                            <Image alt="Contracting photo" src={ServicesContracting} className="services-img" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 services reverse">
                        <div className="col-md-6 services-panel">
                            <Image alt="Small Business Services photo" src={ServicesSmallBusiness} className="services-img" />
                        </div>
                        <div className="col-md-6 services-panel my-auto text-left">
                            <h1 className="font-weight-bold" style={{fontSize: "3.5em"}}>Small Business Services</h1>
                            <p>For small businesses, we offer websites and tactical software solutions to cater for all price points and levels of complexity. </p>
                            <p>Depending on client requirements, websites can be bespoke designed and developed from the ground up with maximum features and flexibility. Alternatively, lower cost template solutions (eg: Webflow, Wordpress) can be built. </p>
                            <p>Bespoke solutions are hosted in Azure, with ReactJS and Sass for the UI and any API/backend functionality in Microsoft .Net Core. Webflow and wordpress hosting can also be arranged.</p>
                            <p>No matter how big or small, <a href={globalNavItems.find(n=>n.key==="contactus")?.key}>{globalNavItems.find(n=>n.key==="contactus")?.linkText}</a> to discuss your requirements and obtain a no-obligation quote.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer/>
    </>;
}

export default Services;