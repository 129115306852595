import { NavItem, NavItemType } from "../models/navItem";

export const defaultNavItem: NavItem = {
    linkText: "",
    linkUrl: "",
    type: NavItemType.link,
    key: ""
}
export const globalNavItems: NavItem[] = [
    {...defaultNavItem, linkText: "Services", linkUrl: "/services", key:"services"},
    {...defaultNavItem, linkText: "Featured Skills", linkUrl: "/myfeaturedskills", key:"myfeaturedskills"},
    {...defaultNavItem, linkText: "Previous Clients", linkUrl: "/previousclients", key:"previousclients"},
    {...defaultNavItem, linkText: "Testimonials", linkUrl: "/testimonials", key:"testimonials"},
    {linkText: "Contact Us", linkUrl: "/contactus", type: NavItemType.button, key:"contactus"},
]
