const constructUrl = (apiEndpoint: string) => {
    let endpoint = `${process.env.REACT_APP_API_URL + apiEndpoint}`;
    if(process.env.REACT_APP_API_KEY){
        endpoint+=`?code=${process.env.REACT_APP_API_KEY}`;
    }
    return endpoint;
}

export const apiPost = async (apiEndpoint: string, requestBody: string) =>{
    var endpoint = constructUrl(apiEndpoint);
    return fetch(endpoint,
    {
        method: "post",
        headers: new Headers({
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json"
          }),
        mode:"no-cors",
        body: requestBody
    })
}
