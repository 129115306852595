import * as React from "react";
import Header from "../../sections/header/header";
import "./contactus.scss";
import ContactUsAddress from './../../assets/contactus-address.gif';
import ContactUsPhone from './../../assets/contactus-phone.gif';
import ContactUsEmail from './../../assets/contactus-email.gif';
import ContactForm from "../../sections/contactForm/contactForm";
import Footer from "../../sections/footer/footer";
interface ContactUsProps
{
    signalrUrl: string | undefined;
}
const ContactUs: React.FC<ContactUsProps> = (props) => {

    return <>
    <div className="contactus-bg1"><Header/>
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col contactus-overlay">
                        <div className="contactus-overlay-welcomeText">
                            <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                                <h1 className="contactus-overlay-welcomeText">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="contactus-bg3">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 contactus top">
                        <div className="col-12 contactus-title text-center">
                            <h1 className="display-4 font-weight-bold">Get in touch with us</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 contactus">
                        <div className="container col-lg-10 contactus-panel-container mx-auto mt-5">
                            <div className="row contactus-form my-5">
                                <div className="col-md-8">
                                    <ContactForm signalrUrl={props.signalrUrl}/> 
                                </div>    
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-sm-12 contactus-panel">
                                            <div className="row">
                                                <div className="col-sm-3 my-auto">
                                                    <img src={ContactUsAddress} alt="Address icon" className="contactus-img py-2" />
                                                </div>
                                                <div className="col-sm-9 px-1 py-2 my-auto">
                                                    <p className="text-uppercase font-weight-bold">Postal Address:</p>
                                                    <p>Joel Blake Ltd, 9 Hudson Close, Haverhill, Suffolk. CB9 0LF</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 contactus-panel my-3">
                                            <div className="row">
                                                <div className="col-sm-3 my-auto">
                                                    <img src={ContactUsPhone} alt="Phone icon" className="contactus-img py-2" />
                                                </div>
                                                <div className="col-sm-9 px-1 py-2 my-auto">
                                                    <p className="text-uppercase font-weight-bold">Phone:</p>
                                                    <p>07812 052769</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 contactus-panel">
                                            <div className="row">
                                                <div className="col-sm-3 my-auto">
                                                    <img src={ContactUsEmail} alt="Email icon" className="contactus-img py-2" />
                                                </div>
                                                <div className="col-sm-9 px-1 py-2 my-auto">
                                                    <p className="text-uppercase font-weight-bold">Email:</p>
                                                    <p><a href="mailto:joel@joelblake.co.uk" title="email link for joel@joelblake.co.uk">joel@joelblake.co.uk</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer/>
    </>;
}

export default ContactUs;