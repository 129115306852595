import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Services from './screens/services/services';
import Testimonials from './screens/testimonials/testimonials';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Home from './screens/home/home';
import './styles/index.scss';
import ContactUs from './screens/contactUs/contactUs';
import MyFeaturedSkills from './screens/myFeaturedSkills/myFeaturedSkills';
import PreviousClients from './screens/previousClients/previousClients';
import "aos/dist/aos.css";
import AOS from "aos";
interface AppProps{

}

const App: React.FC<AppProps> = (props:AppProps) => {
  AOS.init();
  return <><GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}>
      <div className="content-bg">
        <Router>
          <Switch>
              <Route path="/services">
                <Services></Services>
              </Route>
              <Route path="/myfeaturedskills">
                <MyFeaturedSkills></MyFeaturedSkills>
              </Route>
              <Route path="/previousclients">
                <PreviousClients></PreviousClients>
              </Route>
              <Route path="/testimonials">
                <Testimonials></Testimonials>
              </Route>
              <Route path="/contactus">
                <ContactUs signalrUrl={process.env.REACT_APP_SIGNALR_URL}></ContactUs>
              </Route>
              <Route path="/">
                <Home signalrUrl={process.env.REACT_APP_SIGNALR_URL}></Home>
              </Route>
          </Switch>
        </Router>
      </div>
    </GoogleReCaptchaProvider>
  </>      
}

export default App;
