import * as React from "react";
import "./home.scss"
import Header from "../../sections/header/header";
import HomeServicesContract from './../../assets/home-services-contract.gif';
import HomeServicesSmallBusiness from './../../assets/home-services-smallbusiness.gif';
import HomeSkillsAzure from './../../assets/home-skills-azure.gif';
import HomeSkillsCSharp from './../../assets/home-skills-csharp.gif';
import HomeSkillsNetCore from './../../assets/home-skills-netcore.gif';
import HomeSkillsReact from './../../assets/home-skills-reactjs.gif';
import HomeSkillsSqlServer from './../../assets/home-skills-sqlserver.gif';
import HomeSkillsMongo from './../../assets/home-skills-mongo.gif';
import { Carousel } from "react-bootstrap";
import HomeContactHeadshot from './../../assets/home-contact-headshot.jpg';
import ContactForm from "../../sections/contactForm/contactForm";
import Footer from "../../sections/footer/footer";


interface HomeProps
{
    signalrUrl: string | undefined;
}
const Home: React.FC<HomeProps> = (props) => {
    const [carouselIndex, setCarouselIndex] = React.useState(0);

    const handleSelect = (selectedIndex: any, e: any) => {
        setCarouselIndex(selectedIndex);
    };

    return <>
    <div className="bg1"><Header/>
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                        <div data-aos="fade-right" data-aos-delay={100} data-aos-duration={400}>
                        <div className="col-md-7 home-overlay">
                            <div className="home-overlay-welcomeText" style={{fontSize: "2.7em"}}>
                                Welcome To
                            </div>
                            <div className="home-overlay-siteName">
                                Joel Blake Limited
                            </div>
                            <div className="home-overlay-welcomeText" style={{fontSize: "1.8em", fontWeight: 400}}>
                                Professional Software Development
                            </div>
                            <div className="home-overlay-welcomeText" style={{marginTop: "20px"}}>
                                Joel Blake Limited was formed in 2010 providing software solutions to business of all sizes in the South East of England.
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg2">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-md-12 home-about top">
                        <div className="col-md-12 home-about-title text-center">
                            <h1 className="display-4 font-weight-bold">About Us</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 home-about" style={{alignItems: "center"}}>
                        <div className="col-md-10 home-about-body text-center mt-3 mx-auto">
                            <p>Joel Blake Limited was formed in 2010 providing software solutions to business of all sizes in the South East of England.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 home-about">
                        <div className="col-md-6 home-about-body mx-auto mt-5">
                            <p>We have an excellent track record of delivering high quality bespoke software to wide variety of businesses, across the banking and financial services, publishing, energy and property management sectors.</p>
                            <p>Working primarily on the Microsoft stack, our area of expertise is developing Azure based web applications and APIs, complimented with JavaScript based UIs (React or Angular).</p>
                        </div>
                        <div className="col-md-6 home-about-body mx-auto mt-5">
                            <p>Our aim is always to provide a solution that is not only leveraging the most modern technology available but also the most sensible for that particular client, based on existing technology and expertise. </p>
                            <p>Our approach is to assess the client’s needs in terms of features &amp; scale together with non-oral requirements and limitation such as security, time-to-market and cost, and match that to a solution that not only satisfies the requirements but remains supportable, extensible and ultimately relevant long into the future.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg3">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 home-services top">
                        <div className="col-12 home-services-title text-center">
                            <h1 className="display-4 font-weight-bold">Our Services</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 home-services" style={{alignItems: "center"}}>
                        <div className="col-10 home-services-body text-center mt-3 mx-auto">
                            <p>Joel Blake Limited caters for all software development requirements from commercial line of business applications for corporate clients to websites for small/medium sized businesses.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 home-services" id="services-anchor">
                        <div className="col-10 home-services-panel-container text-center mx-auto mt-5">
                            <div className="row">
                                <div className="col-md home-services-panel m-2 py-2 text-center">
                                    <div data-aos="fade-down" data-aos-duration={400} data-aos-offset={300} data-aos-anchor="#services-anchor" data-aos-anchor-placement="top-bottom">
                                        <img alt="Contracting" src={HomeServicesContract} className="img-fluid my-5"/>
                                        <h4 className="text-uppercase font-weight-bold">Contracting</h4>
                                        <p>We develop commercial business applications on a contract basis. We have over 15 years experience delivering high quality software in a diverse range of industries.</p>
                                    </div>
                                </div>
                                <div className="col-md home-services-panel m-2 py-2 text-center">
                                    <div data-aos="fade-down" data-aos-duration={400} data-aos-offset={300} data-aos-anchor="#services-anchor" data-aos-anchor-placement="top-bottom">
                                        <img alt="Small Business Services" src={HomeServicesSmallBusiness} className="img-fluid my-5"/>
                                        <h4 className="text-uppercase font-weight-bold">Small Business Services</h4>
                                        <p>We offer websites and tactical software solutions to cater for all price points and levels of complexity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div className="row">
                    <div className="col-12 home-services">
                        <div className="col-12 text-center" style={{fontSize: "2.7em"}}>
                            <a href="/services" className="btn btn-primary home-services-btn pt-1 mt-5">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg4">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 home-featuredskills top">
                        <div className="col-12 home-featuredskills-title text-center">
                            <h1 className="display-4 font-weight-bold">Featured Skills</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 home-featuredskills">
                        <div className="col-lg-10 home-featuredskills-panel-container mx-auto my-5" id="featured-skills-anchor">
                            <div className="row">
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-up" data-aos-duration={400} data-aos-delay={0} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt="Azure" src={HomeSkillsAzure} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Senior</h4>
                                    <h4 className="home-featuredskills-subtitle">Azure</h4>
                                    <p>5 years experience with Azure including App Services, ServiceBus &amp; CosmosDB amongst others.</p>
                                    </div>
                                </div>
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-up" data-aos-duration={400} data-aos-delay={150} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt=".Net Core" src={HomeSkillsNetCore} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Senior</h4>
                                    <h4 className="home-featuredskills-subtitle">.Net Core</h4>
                                    <p>5 years experience with .Net Core up to v3.1.</p>
                                    </div>
                                </div>
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-up" data-aos-duration={400} data-aos-delay={300} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt="C Sharp" src={HomeSkillsCSharp} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Senior</h4>
                                    <h4 className="home-featuredskills-subtitle">C#</h4>
                                    <p>15 years experience writing software with C# up to v8</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-down" data-aos-duration={400} data-aos-delay={0} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt="SQL Server" src={HomeSkillsSqlServer} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Senior</h4>
                                    <h4 className="home-featuredskills-subtitle">SQL Server</h4>
                                    <p>16 years experience with SQL Server up to v2019 and including Azure PaaS SQL.</p>
                                    </div>
                                </div>
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-down" data-aos-duration={400} data-aos-delay={150} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt="MongoDB" src={HomeSkillsMongo} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Senior</h4>
                                    <h4 className="home-featuredskills-subtitle">MongoDB</h4>
                                    <p>7 years experience working with NoSQL databases, particularly MongoDB and CosmosDB.</p>
                                    </div>
                                </div>
                                <div className="col-sm home-featuredskills-body">
                                    <div data-aos="zoom-in-down" data-aos-duration={400} data-aos-delay={300} data-aos-offset={200} data-aos-anchor="#featured-skills-anchor" data-aos-anchor-placement="top-bottom">
                                    <div className="home-featuredskills-imgbg my-3">
                                        <img alt="ReactJs" src={HomeSkillsReact} className="img-fluid home-featuredskills-img"/>
                                    </div>
                                    <h4 className="home-featuredskills-emphasis">Mid-Weight</h4>
                                    <h4 className="home-featuredskills-subtitle">ReactJs</h4>
                                    <p>1 year working with ReactJs. Have experience with other JS frameworks too.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-12 home-featuredskills">
                        <div className="col-12 text-center" style={{fontSize: "2.7em"}}>
                            <a href="/myfeaturedskills" className="btn btn-primary home-featuredskills-btn pt-1 mt-5">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg5">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 home-testimonials top">
                        <div className="col-12 home-testimonials-title text-center">
                            <h1 className="display-4 font-weight-bold">Testimonials</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 home-testimonials">
                        <div className="container col-lg-10 home-testimonials-panel-container mx-auto mt-5">
                            <div className="row">
                                <div className="col-sm">
                                    <h4 className="home-testimonials-subtitle pl-2">What our clients say</h4>
                                </div>
                            </div>
                            <div className="row">    
                                <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
                                    <Carousel.Item className="home-testimonials-body pl-3">
                                        <div className="col-md">
                                            <p className="text-justify">Joel is a very well respected and highly regarded member of our development team. His many years experience as a senior software engineer are evident in his ability to take on the toughest challenges anywhere in the stack, frequently and willingly getting out of his comfort zone to become an expert in all areas from React/Redux progressive web applications to .NET Core, Azure Functions, Cognitive Services, SQL, NoSQL, DevOps, REST, CQRS and Azure Cloud architecture in general. </p>
                                            <h4 className="home-testimonials-emphasis text-left">Daniel Cook - Software Engineering Tech Lead<br/> 4th June 2020</h4>
                                        </div>
                                        <div className="col-md">
                                            <h2 className="display-5 font-weight-bold">CBRE</h2>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="home-testimonials-body pl-3">
                                        <div className="col-md">
                                            <p className="text-justify">The project was very challenging, technically due to legacy systems and knowledge base and required the whole team to work very collaboratively. Joel brought his industry experience and skill to help progress the project. His ‘can do’ attitude and pragmatic approach, really helped us solve some difficult issues. His tenacity in resolving tough code issues meant that we could move forward. He also mentored less experienced members of the team and was never afraid to think outside the box. I would definitely work with Joel again, he was and remains an asset to any team.</p>
                                            <h4 className="home-testimonials-emphasis text-left">Tania Brawn - Senior Project Manager<br/> 20th May 2018</h4>
                                        </div>
                                        <div className="col-md">
                                            <h3 className="display-5 font-weight-bold">Coventry Building Society</h3>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="home-testimonials-body pl-3">
                                        <div className="col-md">
                                            <p className="text-justify">Joel is an incredibly bright, dedicated and skilled developer. His ability to not just resolve software issues, but resolve them in the right way is excellent and his dedication to delivering high quality work at a fast pace continually impressed me. He fits really well into teams; whilst working for me he played a key role in delivering a £5.5M C# project.</p>
                                            <h4 className="home-testimonials-emphasis text-left">Davoc Bradley - Senior Development Manager<br/> 21st June 2018</h4>
                                        </div>
                                        <div className="col-md">
                                            <h3 className="display-5 font-weight-bold">Coventry Building Society</h3>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-12 home-testimonials">
                        <div className="col-12 text-center" style={{fontSize: "2.7em"}}>
                            <a href="/testimonials" className="home-testimonials-btn btn btn-light  pt-1 mt-5">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg6">
        <div className="content-row">
            <div className="container-md">
                <div className="row">
                    <div className="col-12 home-contact top">
                        <div className="col-12 home-contact-title text-center">
                            <h1 className="display-4 font-weight-bold">Contact Us</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 home-contact">
                        <div className="container col-lg-10 home-contact-panel-container mx-auto mt-5">
                            <div className="row">
                                <div className="col-md-12 home-contact-body text-center">
                                    Please use the form below to check my availability or to schedule a call so we can discuss your requirements.

                                </div>
                            </div>
                            <div className="row home-contact-form mt-5">
                                <div className="col-md-8">
                                    <ContactForm signalrUrl={props.signalrUrl}/> 
                                </div>    
                                <div className="col-md-4 text-center">
                                    <img alt="Joel Blake" src={HomeContactHeadshot} className="img-fluid home-contact-img"/>
                                </div>    
                            </div>                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>;
}

export default Home;